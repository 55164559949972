export const ellipsis = (str:string, max:number = 140, ellipse:string = '...', chars:string[] = [' ','-'], truncate:boolean|string = true):string  => {
    if (str.length < max) return str;

    var last = 0,
        c = '',
        midMax = Math.floor(max / 2),
        computedMax = truncate === 'middle' ? midMax : max - 1;

    for (var i = 0, len = str.length; i < len; i++) {
        c = str.charAt(i);

        if (chars.indexOf(c) !== -1 && truncate !== 'middle') {
            last = i;
        }

        if (i < computedMax) continue;
        if (last === 0) {
            return !truncate ?
                '' :
                str.substring(0, computedMax - 1) + ellipse + (
                    truncate === 'middle' ?
                        str.substring(str.length - midMax, str.length) :
                        ''
                );
        }

        return str.substring(0, last) + ellipse;
    }

    return str;
}
