import * as React from 'react';
import PersonalBlogWrapper from './style';
import Banner from './banner';
import {Siteprops} from "../../../../interfaces/siteprops";
import Posts from "./posts";

type PersonalBlogProps = {
    siteProps:Siteprops
};

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = (props) => {
  return (
    <PersonalBlogWrapper {...props}>
      <Banner image={props.siteProps.home_banner_image} />

        <Posts/>

    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
