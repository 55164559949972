import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import PersonalBlog from '../containers/home';
import SEO from '../components/seo';
import {Siteprops} from "../../../interfaces/siteprops";

const HomePage = (props: any) => {
    const { data } = props;


    const meta = data.wordpressSiteMetadata as Siteprops;

    return (
        <Layout>
            <SEO
                title={meta.title}
                description={meta.description}
            />
            <PersonalBlog siteProps={meta} />
        </Layout>
    );
};

export default HomePage;

export const pageQuery = graphql`
  
   
    query {
         wordpressSiteMetadata {
         
              logo_title
              home_banner
              home_banner_image {
                    childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH) 
                }
    }
              home
              description
              title
            
    }}
`;
