import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import FeaturePost from '../../../components/feature-post/feature-post';
import { BannerInner, FeaturePosts, Title } from './style';
import {ellipsis} from "../../../../../shared/ellipsis";
import {GatsbyImage, getImage, IGatsbyImageData} from "gatsby-plugin-image";

type BannerProps = {
    image:any;
};

const Banner: React.FunctionComponent<BannerProps> = (props) => {

  const Data = useStaticQuery(graphql`

query BannerQuery2 {
  allWordpressPost(limit: 4, sort: {fields: date, order: DESC}) {
    edges {
      node {
      categories{
      slug
      name
      }
       tags{
      slug
      name
      }
        ...PostInfos
        
      }
    }
  }
}

`);

  const shortenTitle = (str:string):string => {


      if(str.length>50){
          return ellipsis(str,50);
      }
      return str;
  }

  const Posts = Data.allWordpressPost.edges;

    const image = getImage(props.image) as IGatsbyImageData;
        console.log(image);

  return (
    <>



        <div id="test" style={{ display: "grid" }}>
            {/* You can use a GatsbyImage component if the image is dynamic */}
            <GatsbyImage className={'test'}   style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                 maxHeight: 600,
            }} alt={'test'} image={image}/>
            <div
                style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                }}
            >
                {/* Any content here will be centered in the component */}
                <BannerInner>
                    <FeaturePosts>
                        <Title>Featured Posts</Title>
                        {Posts.map(({ node }: any) => {
                            const title = node.title || node.slug;
                            // Random Placeholder Color
                            const placeholderColors = [
                                '#55efc4',
                                '#81ecec',
                                '#74b9ff',
                                '#a29bfe',
                                '#ffeaa7',
                                '#fab1a0',
                                '#e17055',
                                '#0984e3',
                                '#badc58',
                                '#c7ecee',
                            ];
                            const setColor =
                                placeholderColors[
                                    Math.floor(Math.random() * placeholderColors.length)
                                    ];

                            return (
                                <FeaturePost
                                    key={node.slug}
                                    title={shortenTitle(title)}
                                    image={
                                        (null == node.featured_media || null === node?.featured_media?.localFile?.childImageSharp?.fluid)
                                            ? null
                                            : node.featured_media.localFile.childImageSharp.fluid
                                    }
                                    url={node.slug}
                                    tags={node.tags}
                                    placeholderBG={setColor}
                                />
                            );
                        })}
                    </FeaturePosts>
                </BannerInner>
            </div>
        </div>
        </>
  );
};

export default Banner;
