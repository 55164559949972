import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostCardModern from '../../../components/post-card-modern/post-card-modern';
import BlogPostsWrapper, {PostGrid, PostRow} from "../../../components/post/post.style";

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {

    const Data = useStaticQuery(graphql`

query BannerQueryBottom {
  allWordpressPost(limit: 10, skip:4, sort: {fields: date, order: DESC}) {
    edges {
      node {
      categories{
      slug
      name
      }
       tags{
      slug
      name
      }
        ...PostInfos
        
      }
    }
  }
}

`);

  const Posts = Data.allWordpressPost.edges;

  return (
    <BlogPostsWrapper>
      <PostRow>
        {Posts.map(({ node }: any) => {
          const title = node.title || node.fields.slug;
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ];

          return (
            <PostGrid key={node.slug}>
              <PostCardModern
                key={node.slug}
                title={title}
                image={
                    (null == node.featured_media || null === node?.featured_media?.localFile?.childImageSharp?.fluid)
                        ? null
                        : node.featured_media.localFile.childImageSharp.fluid
                }
                url={node.slug}
                description={node.excerpt}
                tags={node.tags}
                placeholderBG={setColor}
              />
            </PostGrid>
          );
        })}
      </PostRow>

    </BlogPostsWrapper>
  );
};

export default Posts;
